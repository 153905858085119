import { useRef, useEffect } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

import CloseButton from 'components/Button/CloseButton';
import Image from 'components/Image';

import useBlockScroll from 'hooks/useBlockScroll';

import BREAKPOINTS from 'styles/breakpoints';
import COLORS from 'styles/colors';
import zIndex from 'styles/zIndex';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #003234e6;
  color: ${COLORS.white};
  z-index: ${zIndex.modalOverlay};

  &:focus {
    outline: none;
  }
`;

const CloseButtonStyled = styled(CloseButton)`
  position: absolute;
  top: 15px;
  right: 15px;

  ${BREAKPOINTS.large`
    top: 40px;
    right: 40px;
  `}
`;

const SliderStyled = styled(Slider)`
  width: 100%;
  max-width: 900px;

  .slick-list {
    height: inherit;
  }

  .slick-track {
    align-items: center;
  }

  .slick-slide {
    pointer-events: none;

    &.slick-current {
      pointer-events: initial;
    }
  }

  ${BREAKPOINTS.small`
    width: 80vw;
  `}

  @media (min-height: 850px) {
    max-width: 1050px;
  }
`;

const LightBoxImage = styled.a`
  position: relative;
  width: 100%;
  display: block;
  overflow: hidden;
  cursor: ${(props) => (props.href ? 'pointer' : 'default')};

  ${BREAKPOINTS.small`
    border-radius: 8px;
  `}
`;

const ArrowNext = styled.div`
  display: none;
  padding: 20px 40px 20px 20px;
  top: 50%;
  left: -80px;
  width: 20px;
  height: 19px;
  background: url('/images/icons/arrow.svg') no-repeat center;
  transform: translateY(-50%);
  cursor: pointer;
  box-sizing: content-box;
  z-index: 100;

  ${BREAKPOINTS.small`
    display: block;
  `}
`;

const ArrowPrev = styled(ArrowNext)`
  padding: 20px 20px 20px 40px;
  transform: translateY(-50%) rotate(180deg);
  right: -80px;
  left: auto;
`;

const ImageLightBox = ({ images, handleClose, activeImageIdx }) => {
  const sliderRef = useRef();

  useBlockScroll(true);

  useEffect(() => {
    sliderRef.current?.slickGoTo(activeImageIdx);
  }, [activeImageIdx]);

  const sliderSettings = {
    slidesToShow: 1,
    dots: false,
    arrows: true,
    fade: true,
    focusOnSelect: true,
    infinite: true,
    initialSlide: activeImageIdx,
    nextArrow: <ArrowNext />,
    prevArrow: <ArrowPrev />,
  };
  return (
    <Container aria-modal aria-hidden tabIndex={-1} role="dialog">
      <CloseButtonStyled
        size="medium"
        aria-label="close"
        onClick={handleClose}
      />

      <SliderStyled
        ref={(slider) => (sliderRef.current = slider)}
        {...sliderSettings}
      >
        {images.map((image, idx) => (
          <LightBoxImage key={idx} href={image.link ? image.link : undefined}>
            <Image image={image} />
          </LightBoxImage>
        ))}
      </SliderStyled>
    </Container>
  );
};

export default ImageLightBox;
