import * as React from 'react';
import Link from 'next/link';
import styled, { css } from 'styled-components';

import COLORS from 'styles/colors';
import TYPO, { rem, weight } from 'styles/typography';

import { isExternalLink } from 'utils/helpers';

const ButtonStyled = styled.button<{
  small?: boolean;
  outline?: boolean;
}>`
  ${TYPO.p2}
  padding: 15px 30px;
  display: inline-block;
  background-color: ${COLORS.dawn};
  color: ${COLORS.white};
  font-weight: ${weight.semibold};
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border-radius: 50px;
  text-decoration: none;
  transition: 0.4s;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  ${({ small }) =>
    small &&
    css`
      padding: 11px 24px;
      font-size: ${rem(10)};
    `}

  &:hover {
    background-color: ${COLORS.dawnDark};
  }

  ${({ outline }) =>
    outline &&
    css`
      background-color: transparent;
      border: 1px solid ${COLORS.dawn};
      color: ${COLORS.dawn};

      &:hover {
        background-color: transparent;
      }
    `}
`;

type ButtonProps = React.ComponentProps<typeof ButtonStyled> & {
  type?: 'button' | 'submit' | 'reset';
  onClick?: () => void;
  href?: string;
  outline?: boolean;
  children: React.ReactNode;
};

const Button = ({
  type = 'button',
  onClick,
  href,
  outline = false,
  children,
  ...props
}: ButtonProps) => {
  if (href && isExternalLink(href)) {
    return (
      <ButtonStyled
        onClick={(e) => {
          e.preventDefault();
          // Open the link in a new window or tab in a way that prevents
          // the new page from accessing the window.opener property.
          // This is for security reasons to prevent the new page from
          // having any control over the page that opened it.
          const newWindow = window.open(href, '_blank', 'noopener,noreferrer');
          // This is an additional safeguard for browsers that don't support 'noopener'.
          if (newWindow) newWindow.opener = null;
        }}
        outline={outline}
        {...props}
      >
        {children}
      </ButtonStyled>
    );
  }

  if (href) {
    return (
      <ButtonStyled
        onClick={(e) => {
          e.preventDefault();
          const newWindow = window.open(href, '_self');
        }}
        outline={outline}
        {...props}
      >
        {children}
      </ButtonStyled>
    );
  }

  if (type === 'submit') {
    return (
      <ButtonStyled type={type} outline={outline} {...props}>
        {children}
      </ButtonStyled>
    );
  }

  return (
    <ButtonStyled onClick={onClick} type={type} outline={outline} {...props}>
      {children}
    </ButtonStyled>
  );
};

export default Button;
