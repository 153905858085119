import { useEffect } from 'react';

const doBlock = (blocked) => {
  if (typeof document !== 'undefined') {
    const newValue = blocked ? 'hidden' : 'initial';

    document.querySelector('html').style.overflow = newValue;
    document.querySelector('body').style.overflow = newValue;
  }
};

export default function useBlockScroll(blocked) {
  useEffect(() => {
    doBlock(blocked);

    return () => {
      doBlock(false);
    };
  }, [blocked]);
}
