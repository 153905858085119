import { useRef, useEffect } from 'react';
import Slider from 'react-slick';
import styled, { css } from 'styled-components';

import Image from 'components/Image';

import BREAKPOINTS from 'styles/breakpoints';
import { useRouter } from 'next/router';

const SliderStyled = styled(Slider)`
  cursor: ${({ cursorStyle }) => cursorStyle};
`;

const SlideItem = styled.div`
  margin: 0 10px;
  position: relative;
  width: 220px;
  height: 280px;

  ${({ imageSize }) => {
    if (imageSize === 'landscape') {
      return css`
        margin: 0 7px;
        width: 330px;
        height: 186px;
      `;
    }
  }}

  border-radius: 5px;
  overflow: hidden;

  ${BREAKPOINTS.small`
    margin: 0 10px;
    width: 575px;
    height: 323px;

    ${({ imageSize }) => {
      if (imageSize === 'landscape') {
        return css`
          width: 725px;
          height: 408px;
        `;
      }
    }}
  `}
`;

const ImageSlider = ({
  images,
  shouldAutoPlay = true,
  handleOpenImageLightBox,
  className,
  imageSize = 'default',
  customSettings,
}) => {
  const sliderRef = useRef();
  const hasImageLightBox = !!handleOpenImageLightBox;
  const { push } = useRouter();

  useEffect(() => {
    if (shouldAutoPlay) {
      sliderRef.current?.slickPlay();
    } else {
      sliderRef.current?.slickPause();
    }
  }, [shouldAutoPlay]);

  const settings = {
    arrows: false,
    dots: false,
    slidesToShow: 2,
    variableWidth: true,
    centerMode: true,
    infinite: true,
    speed: 4000,
    autoplaySpeed: 100, // Pause between slides
    swipeToSlide: true,
    autoplay: true,
    draggable: hasImageLightBox ? false : true,
    ...customSettings,
  };

  if (!images.length) return null;

  return (
    <SliderStyled
      ref={(slider) => (sliderRef.current = slider)}
      {...settings}
      className={className}
      cursorStyle={hasImageLightBox ? 'pointer' : 'grab'}
    >
      {images.map((image, i) => (
        <div key={i}>
          <SlideItem
            imageSize={imageSize}
            onClick={() => {
              if (image.link) {
                if (image.link.startsWith('/')) {
                  push(image.link);
                } else {
                  window.open(image.link, '_blank');
                }
              } else if (hasImageLightBox) {
                handleOpenImageLightBox(i);
              }
            }}
          >
            <Image image={image} layout="fill" />
          </SlideItem>
        </div>
      ))}
    </SliderStyled>
  );
};

export default ImageSlider;
