import { useEffect, useState } from 'react';
import styled from 'styled-components';
import throttle from 'lodash.throttle';

import BREAKPOINTS from 'styles/breakpoints';
import FONTS from 'styles/fonts';
import { rem, weight } from 'styles/typography';

import { clamp, isMobileDevice } from 'utils/helpers';

const TextSvg = styled.svg`
  position: absolute;
  top: 17px;
  left: 0;

  ${BREAKPOINTS.custom(600)`
    top: 0;
  `}

  text {
    font-family: ${FONTS.wulkan};
    font-weight: ${weight.black};
    font-size: calc(102px - 4vw);
    font-feature-settings: var(--font-feature-settings-normal);

    ${BREAKPOINTS.small`
      font-size: ${rem(65)};
    `}

    ${BREAKPOINTS.medium`
      font-size: ${rem(50)};
    `}
  }
`;

let distance = 0;
let scrollPos = 0;
let startOffset = 0;
let animationFrameId;

const AnimateSvgText = ({ elem, selector, containerRef, min, max, inView }) => {
  const [scrollOffset, setScrollOffset] = useState(0);

  // threshold: Amount of pixels above container
  // top: Top of container relative to window
  // scrollTop: distance from top of window
  useEffect(() => {
    const threshold = 600;
    const { top } = containerRef?.current?.getBoundingClientRect();
    const { scrollTop } = document.documentElement;

    setScrollOffset(top + scrollTop - threshold);
  }, [containerRef, scrollOffset, setScrollOffset]);

  useEffect(() => {
    const interpolate = 15;
    const textPath = document.querySelector(`.${selector}`);

    const scroll = () => {
      scrollPos =
        (window.pageYOffset || document.documentElement.scrollTop) -
        scrollOffset;
    };

    const animateTextPath = () => {
      distance = scrollPos - startOffset;
      startOffset += distance / interpolate;

      const value = clamp(startOffset, min, max);
      textPath.setAttribute('startOffset', value);

      animationFrameId = requestAnimationFrame(animateTextPath);
    };

    const throttleScroll = throttle(scroll, 100);

    // Trigger calculating the scroll position when hash routing
    const scrollToSection = window.location.hash;

    if (scrollToSection) {
      throttleScroll();
    }

    if (inView && !isMobileDevice()) {
      animationFrameId = requestAnimationFrame(animateTextPath);
      window.addEventListener('scroll', throttleScroll);
    }
    return () => {
      cancelAnimationFrame(animationFrameId);

      throttleScroll.cancel();
      window.removeEventListener('scroll', throttleScroll);
    };
  }, [inView, selector, scrollOffset, min, max, containerRef]);

  return <TextSvg as={elem} />;
};

export default AnimateSvgText;
