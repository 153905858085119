import styled, { css } from 'styled-components';

import Slider from 'components/Slider';
import Image from 'components/Image';
import Button from 'components/Button';

import COLORS from 'styles/colors';
import BREAKPOINTS from 'styles/breakpoints';
import { H3, H5, P1 } from 'styles/typography';
import { Grid, columns } from 'styles/grid';
import { useBookButton } from 'src/hooks/useBookButton';

import { isEven } from 'utils/helpers';

const Section = styled.section`
  color: ${COLORS.pinkMillennial};
  padding-bottom: 60px;

  ${BREAKPOINTS.small`
    padding-bottom: 150px;
  `}
`;

const Container = styled.div`
  max-width: 1130px;
  margin: 0 auto;
`;

const SectionHeading = styled(H3)`
  margin-bottom: 60px;
  text-align: center;
  transition: 0.8s;
  padding: 0 var(--gutter-side);

  ${BREAKPOINTS.small`
    margin-bottom: 125px;
  `}

  ${BREAKPOINTS.medium`
    opacity: var(--hero-fade-in);
  `}
`;

const MobileLayout = styled.div`
  .slick-dots {
    position: static;
    margin-top: 50px;
    li button::before {
      background-color: ${COLORS.pinkMillennial.opacity(0.2)};
    }

    li.slick-active button::before {
      background-color: ${COLORS.pinkMillennial};
    }
  }
  ${BREAKPOINTS.small`
    display: none;
  `}
`;

const DesktopLayout = styled.div`
  display: none;
  transition: 0.6s 0.4s;

  ${BREAKPOINTS.small`
    display: block;
  `}

  ${BREAKPOINTS.medium`
    opacity: var(--hero-fade-in);
  `}
`;

const Hr = styled.hr`
  ${columns(2, 10)}
  display: none;
  margin: 50px 0;
  width: 100%;
  border: 0;
  height: 1px;
  background-color: ${COLORS.dawn.opacity(0.2)};

  ${BREAKPOINTS.small`
    display: block;
  `}
`;

const Card = styled(Grid)`
  &:last-child ${Hr} {
    display: none;
  }

  ${BREAKPOINTS.small`
    padding: 0;
    display: flex;
  `}
`;

const CardInner = styled.div`
  ${columns(null, 12)}
  margin-bottom: 20px;
  position: relative;

  ${BREAKPOINTS.small`
    margin-bottom: 0;
    ${(props) => (props.reverse ? columns(7, 5) : columns(2, 5))}
  `}

  ${BREAKPOINTS.medium`
    margin-right: 40px;
    margin: ${(props) => (props.reverse ? '0 0 0 40px' : '0 40px 0 0')}
  `}
`;

const CardImage = styled(Image)`
  max-height: 220px;

  ${BREAKPOINTS.small`
    max-height: 300px;
  `}
`;

const CardContent = styled.div`
  ${columns(null, 12)}

  ${BREAKPOINTS.small`
    ${columns(7, 5)}
    padding-left: 40px;
    max-width: 420px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-self: start;

    ${(props) =>
      props.reverse &&
      css`
        ${columns(2, 5)}
        padding: 0 40px 0 0;
        justify-self: start;
      `}
  `}
`;

const StyledCardCta = styled(Button)`
  margin-top: 14px;
  width: fit-content;

  ${BREAKPOINTS.small`
    margin-top: 32px;
  `}
`;

const CardCta = ({ children, href, ...props }) => {
  const { handleBookButtonClick } = useBookButton();

  return href ? (
    <StyledCardCta href={href} {...props}>
      {children || 'Book now'}
    </StyledCardCta>
  ) : (
    <StyledCardCta onClick={handleBookButtonClick} {...props}>
      {children || 'Book now'}
    </StyledCardCta>
  );
};

const Item = ({
  item: { title, image, description, ctaLabel, ctaLink },
  index,
}) => {
  return (
    <Card>
      <CardInner reverse={isEven(index)}>
        <CardImage image={image} />
      </CardInner>
      <CardContent reverse={isEven(index)}>
        <H5 as="h3" small weight="semibold">
          {title}
        </H5>
        <P1 indent>{description}</P1>
        {ctaLabel && <CardCta href={ctaLink}>{ctaLabel}</CardCta>}
      </CardContent>
      <Hr />
    </Card>
  );
};

const KeyFeatures = ({ title, items, className }) => {
  return (
    <Section className={className}>
      <Container>
        <SectionHeading weight="bold">{title}</SectionHeading>

        <MobileLayout>
          <Slider>
            {items?.map((item, i) => (
              <Item key={item.title} item={item} index={i + 1} />
            ))}
          </Slider>
        </MobileLayout>

        <DesktopLayout>
          {items?.map((item, i) => (
            <Item key={item.title} item={item} index={i + 1} />
          ))}
        </DesktopLayout>
      </Container>
    </Section>
  );
};

export default KeyFeatures;
