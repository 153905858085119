import Image from "next/legacy/image";
import useDimensions from 'react-cool-dimensions';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  width: inherit;
  height: inherit;
  border-radius: 5px;
  overflow: hidden;
`;

const ImageStyled = styled(Image)`
  border-radius: 5px;
`;

const imageLoader = ({ src, width }) => {
  const relativeSrc = (src) => src.split('/').pop();
  return `https://media.graphassets.com/resize=width:${width}/auto_image/compress/${relativeSrc(
    src
  )}`;
};

const ImageHelper = ({
  image,
  layout = 'responsive',
  priority = false,
  ...props
}) => {
  const { observe, width: parentWidth } = useDimensions();

  // Check if image is null or undefined
  if (!image) {
    return null;
  }

  const { url, alt, width, height } = image;

  const sizes = layout !== 'fill' && { width, height };
  return (
    <>
      {url && (
        <Container {...props} ref={observe}>
          <ImageStyled
            loader={imageLoader}
            src={url}
            alt={alt}
            {...sizes}
            layout={layout}
            objectFit="cover"
            priority={priority}
            sizes={
              layout === 'responsive' || layout === 'fill'
                ? `${parentWidth}px`
                : undefined
            }
          />
        </Container>
      )}
    </>
  );
};

export default ImageHelper;
