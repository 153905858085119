import { useEffect } from 'react';

import { useNav } from 'context/NavProvider';

const useIsolateNav = (value) => {
  const { setIsolate } = useNav();

  useEffect(() => {
    setIsolate(value);
    return () => {
      setIsolate(false);
    };
  }, [value, setIsolate]);
};

export default useIsolateNav;
