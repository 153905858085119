import * as React from 'react';
import styled, { css } from 'styled-components';

export const GRID_WIDTH = 1360;
export const COLUMS = 12;

export const columns = (start: number | null, cols: number | null) =>
  start
    ? css`
        grid-column: col-start ${start ?? ''} / span ${cols ?? ''};
      `
    : css`
        grid-column: span ${cols ?? ''};
      `;

export const grid = (col: number) => css`
  display: grid;
  grid-template-columns: repeat(${col}, [col-start] 1fr);
  column-gap: var(--gutter);
  grid-auto-flow: dense;
`;

// detect if background is image
const getBackground = (background) =>
  background.toLowerCase().indexOf('http') > -1 ||
  background.toLowerCase().indexOf('png') > -1 ||
  background.toLowerCase().indexOf('jpg') > -1 ||
  background.toLowerCase().indexOf('svg') > -1
    ? `url(${background})`
    : background;

export const GridOuter = styled.div<{
  background?: string;
}>`
  width: 100%;
  display: flex;
  justify-content: center;
  background: ${({ background }) =>
    background ? getBackground(background) : 'none'};
  background-size: cover;
  background-position: center center;
  padding: 0 var(--gutter-side);
`;

export const GridInner = styled.div`
  position: relative;
  max-width: ${GRID_WIDTH}px;
  width: 100%;
  ${grid(COLUMS)}
`;

export const Column = styled.div<{
  start?: number;
  columns?: number;
}>`
  ${(props) => columns(props.start ?? null, props.columns ?? null)}
`;

type GridProps = {
  children: React.ReactNode;
  className?: string;
  background?: string;
};

export const Grid = ({
  children,
  className,
  background,
  ...props
}: GridProps) => (
  <GridOuter className={className} background={background} {...props}>
    <GridInner>{children}</GridInner>
  </GridOuter>
);
