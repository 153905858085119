import Slider from 'react-slick';

const SlickSlider = ({ children, ...props }) => {
  const settings = {
    arrows: false,
    dots: true,
    infinite: false,
    speed: 800,
    slidesToShow: 1,
    swipeToSlide: true,
  };
  return (
    <Slider {...settings} {...props}>
      {children}
    </Slider>
  );
};

export default SlickSlider;
