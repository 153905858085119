import styled from 'styled-components';

import { CircleButton } from 'styles/global';
import COLORS from 'styles/colors';

const Button = styled.button`
  ${CircleButton}
  padding: var(--padding);
  width: var(--size);
  height: var(--size);

  &:before,
  &:after {
    content: '';
    width: var(--cross-length);
    height: var(--cross-stroke);
    background-color: ${COLORS.white};
  }

  &:before {
    transform: translateX(50%) rotate(45deg);
  }

  &:after {
    transform: translateX(-50%) rotate(-45deg);
  }

  &:hover {
    background-color: ${COLORS.dawnDark};
  }
`;

const BackButton = ({ size = 'small', onClick, className }) => {
  const isMedium = size === 'medium';
  const styles = {
    '--padding': isMedium ? '5px' : '2px',
    '--size': isMedium ? '60px' : '35px',
    '--cross-length': isMedium ? '20px' : '12px',
    '--cross-stroke': isMedium ? '2px' : '1px',
  };
  return (
    <Button
      type="button"
      onClick={onClick}
      className={className}
      style={styles}
    >
      <div />
      <div />
    </Button>
  );
};

export default BackButton;
